













































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Form as elForm } from "element-ui";
import { GetUser, PostEditUser, editPassword } from "@/request/account";
@Component({})
export default class Name extends Vue {
  private ifEditZhanghu: any = false;
  private ifEditMima: any = false;
  private ifEditShouji: any = false;
  private inputType: any = "text";
  private zhanghumingData: any = {
    账户名: "",
  };
  private zhanghumingRules: any = {
    账户名: [{ required: true, message: "请填写账户名", trigger: "blur" }],
  };
  private mimaData: any = {
    旧密码: "",
    新密码: "",
    重复新密码: "",
  };
  private mimaRules: any = {
    旧密码: [{ required: true, message: "请填写旧密码", trigger: "blur" }],
    新密码: [{ validator: this.checkPassword, trigger: "blur" }],
    重复新密码: [{ validator: this.checkRepassword, trigger: "blur" }],
  };
  private shoujihaoData: any = {
    新手机: "",
    重复新手机: "",
  };
  private shoujihaoRules: any = {
    新手机: [{ validator: this.checkPhone, trigger: "blur" }],
    重复新手机: [{ validator: this.checkRephone, trigger: "blur" }],
  };
  private data: any = {};
  private get user() {
    return this.$store.state.user;
  }
  private changeInputType() {
    this.inputType = "password";
  }
  private checkPassword(rule: any, value: any, callback: any) {
    if (!value) {
      return callback(new Error("请填写新密码"));
    } else {
      const reg =
        /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{9,}$/;
      if (!reg.test(value)) {
        callback(
          new Error("密码字符数须大于8位，且包含大小写字母，数字，特殊符号")
        );
      } else {
        callback();
      }
    }
  }
  private checkRepassword(rule: any, value: any, callback: any) {
    if (!value) {
      return callback(new Error("请重复填写新密码"));
    } else {
      const reg =
        /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{9,}$/;
      if (!reg.test(value)) {
        callback(
          new Error("密码字符数须大于8位，且包含大小写字母，数字，特殊符号")
        );
      } else {
        if (value !== this.mimaData["新密码"]) {
          callback(new Error("新密码和重复新密码输入不一致"));
        } else {
          callback();
        }
      }
    }
  }
  private checkPhone(rule: any, value: any, callback: any) {
    if (!value) {
      return callback(new Error("请填写新手机"));
    } else {
      const reg = /^\d{11}$/;
      if (!reg.test(value)) {
        callback(new Error("手机号必须为11位数字"));
      } else {
        callback();
      }
    }
  }
  private checkRephone(rule: any, value: any, callback: any) {
    if (!value) {
      return callback(new Error("请重复填写新手机"));
    } else {
      const reg = /^\d{11}$/;
      if (!reg.test(value)) {
        callback(new Error("手机号必须为11位数字"));
      } else {
        if (value !== this.shoujihaoData["新手机"]) {
          callback(new Error("新手机和重复新手机输入不一致"));
        } else {
          callback();
        }
      }
    }
  }
  private editMsg(val: any) {
    if (val === "用户名") {
      this.ifEditZhanghu = true;
      (this.$refs.zhanghumingForm as elForm).resetFields();
    } else if (val === "手机号") {
      this.ifEditShouji = true;
      (this.$refs.shoujihaoForm as elForm).resetFields();
    } else if (val === "密码") {
      this.ifEditMima = true;
      (this.$refs.mimaForm as elForm).resetFields();
    }
  }
  // 修改账户名
  private editZhanghuming() {
    (this.$refs.zhanghumingForm as elForm).validate((isValid: boolean) => {
      if (isValid) {
        const params = JSON.parse(JSON.stringify(this.data));
        params["用户名"] = this.zhanghumingData["账户名"];
        PostEditUser(this, params).then((res: any) => {
          this.ifEditZhanghu = false;
          this.$message.success("修改成功");
          this.getData();
        });
      }
    });
  }
  // 修改密码
  private editMima() {
    (this.$refs.mimaForm as elForm).validate((isValid: boolean) => {
      if (isValid) {
        const params = {
          原密码: this.mimaData["旧密码"],
          新密码: this.mimaData["新密码"],
        };
        editPassword(this, params).then((res: any) => {
          this.ifEditMima = false;
          this.$message.success("修改成功");
          this.loginOut();
        });
      }
    });
  }
  // 修改手机号
  private editShoujihao() {
    (this.$refs.shoujihaoForm as elForm).validate((isValid: boolean) => {
      if (isValid) {
        const params = JSON.parse(JSON.stringify(this.data));
        params["手机号"] = this.shoujihaoData["新手机"];
        PostEditUser(this, params).then((res: any) => {
          this.ifEditShouji = false;
          this.$message.success("修改成功");
          this.getData();
        });
      }
    });
  }
  private getData() {
    const params = {
      doctor_id: this.$store.state.user.doctor_id,
      source: "pc医生",
    };
    GetUser(this, params).then((res: any) => {
      this.data = res;
      this.$store.commit("updateUser", res);
    });
  }
  private loginOut() {
    this.$store.commit("updateToken", "");
    this.$store.commit("updateUser", null);
    this.$router.push("/login");
  }
  mounted() {
    this.getData();
  }
}
